<script lang="ts">
import { consumer_login_user__, Phone__ChangeModal_i__, VerificationCodeModal_i__ } from '@menus/domain'
import { ui_ctx__getContext } from '@menus/ui'
import { createEventDispatcher, onDestroy, onMount } from 'svelte'
import { Modal } from '../modal/index.js'
import { Phone__VerifyChangeModal_c } from './Phone__VerifyChangeModal_c.js'
const ctx = ui_ctx__getContext()
const dispatch = createEventDispatcher()
const consumer_login_user_ = consumer_login_user__(ctx)
const Phone__ChangeModal_i_ = Phone__ChangeModal_i__(ctx)
const VerificationCodeModal_i_ = VerificationCodeModal_i__(ctx)
export const _ = new Phone__VerifyChangeModal_c(ctx, dispatch)
export const open = _.open, close = _.close, is_modal_open_ = _.is_modal_open_
onMount(()=>_.onMount())
onDestroy(()=>_.onDestroy())
</script>

{#if $is_modal_open_}
	<!-- Phone__VerifyChangeModal -->
	<Modal {_} class="Phone__VerifyChangeModal">
		<div slot="header">
			<button type="button" class="close" on:click={()=>close({ verified: false })}>
				<div class="delete-icon"></div>
			</button>
			<h4 class="modal-title">Verify Phone</h4>
		</div>
		<div>
			<p>
				Your phone {$consumer_login_user_?.Phone} is not verified.
				Please either verify your current phone or add a new phone.
			</p>
		</div>
		<div slot="footer">
			<div class="row">
				<button type="button"
								class="col-xs-12 btn btn-lg"
								on:click={async ()=>{
									await close()
									$VerificationCodeModal_i_.open({ Phone: $consumer_login_user_.Phone }).then()
								}}
				>Verify Phone</button>
			</div>
			<div class="row">
				<button type="button"
								class="col-xs-12 btn btn-lg"
								on:click={async ()=>{
									await close()
									$Phone__ChangeModal_i_.open().then()
								}}
				>Change Phone</button>
			</div>
			<div class="row">
				<button type="button"
								class="col-xs-12 btn btn-lg"
								on:click={()=>close()}
				>Cancel</button>
			</div>
		</div>
	</Modal>
{/if}

<style lang="scss" global>
@import "@menus/css/lib";
#sapper {
	.Phone__VerifyChangeModal {
		.modal-dialog {
			p {
				margin-top: 8px;
			}
			.modal-footer {
				.row {
					margin-top: 8px;
				}
			}
		}
	}
}
</style>
