<script lang="ts">
import { APP_VERSION__, APP_VERSION_dirty__ } from '@menus/domain'
import { ui_ctx__getContext } from '@menus/ui'
const ctx = ui_ctx__getContext()
const APP_VERSION_ = APP_VERSION__(ctx)
const APP_VERSION_dirty_ = APP_VERSION_dirty__(ctx)
</script>

{#if $APP_VERSION_dirty_}
  <div class="VersionRefresh">
    <div class="text">
      There are new updates ({$APP_VERSION_}). Please refresh this page.
    </div>
    <a href="." class="refresh-link" on:click|preventDefault={()=>window.location.reload()}>refresh</a>
  </div>
{/if}

<style lang="scss" global>
@import "@menus/css/lib";
.VersionRefresh {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	height: 72px;
	width: 100%;
	z-index: 999999;
	background: orange;
	vertical-align: middle;
	.text {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 64px;
	}
	.refresh-link {
		display: flex;
		align-items: center;
		justify-content: center;
		background: $brand-success;
		z-index: 1;
		&:focus {
			text-decoration: none;
		}
	}
}
</style>
