<script lang="ts">
import { ui_ctx__getContext } from '@menus/ui'
import { createEventDispatcher, onDestroy, onMount } from 'svelte'
import { MessageModal_c } from './MessageModal_c.js'
const ctx = ui_ctx__getContext()
const dispatch = createEventDispatcher()
export const _ = new MessageModal_c(ctx, dispatch)
export const open = _.open, close = _.close
const data_ = _.data_
const is_modal_open_ = _.is_modal_open_
onMount(()=>_.onMount())
onDestroy(()=>_.onDestroy())
</script>

{#if $is_modal_open_}
  <div class="modal-backdrop fade in"></div>
	<!-- Message Modal -->
  <div class="modal MessageModal message-modal fade d-block in"
			 tabindex="-1"
			 role="dialog"
			 aria-labelledby="myModalLabel"
	>
    <div class="modal-dialog" role="document" on:click|stopPropagation>
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close"
									on:click={()=>close(false)}
					>
            <div class="delete-icon"></div>
          </button>
          <h4 class="modal-title">{ $data_?.title }</h4>
        </div>
        <div class="modal-body modal-scrollable">
          <div class="message-message">{ $data_?.message }</div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <button type="button"
										class="col-xs-12 btn {$data_?.ok_class}"
										on:click={()=>close(true)}
						>{ $data_?.ok_text }</button>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style lang="scss" global>
@import "@menus/css/lib";
.MessageModal {
	.modal-body {
		padding: 40px;
		.message-message {
			font-size: 18px;
			font-weight: $lato-bold;
		}
	}
	.modal-footer {
		padding: 20px 20px 100px;
		border-top: 1px solid #DBDBDB;
		@media (min-width: $screen-sm-min) {
			padding-bottom: 20px;
			text-align: right;
		}
		.action-buttons {
			.btn {
				@media (max-width: $screen-xs-max) {
					width: 100%;
				}
			}
		}
	}
}
</style>
