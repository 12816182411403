<script lang="ts">
import { ui_ctx__getContext } from '@menus/ui'
import { VerificationCode } from '../consumer-auth/index.js'
import { Modal } from '../modal/index.js'
import { VerificationCodeModal_c } from './VerificationCodeModal_c.js'
const ctx = ui_ctx__getContext()
const _ = new VerificationCodeModal_c(ctx)
export const open = _.open, close = _.close
const Email_ = _.Email_
const Phone_ = _.Phone_
const title_name_ = _.title_name_
const verification_code_ = _.verification_code_
let form_error_a:string[] = []
let verification_code_error_a = []
$: form_error_a = [...verification_code_error_a]
</script>

<Modal {_} class="VerificationCodeModal" title={`Verify ${$title_name_ || ''}`}>
	<VerificationCode
		Email={$Email_}
		Phone={$Phone_}
		change_contact_button__to_render={true}
		bind:verification_code={$verification_code_}
		on:verified={()=>close({ verified: true })}
		on:verification_code__resend={()=>_.verification_code__resend()}
	></VerificationCode>
</Modal>
