<script lang="ts">
import { consumer_login_user__, Email__ChangeModal_i__, VerificationCodeModal_i__ } from '@menus/domain'
import { ui_ctx__getContext } from '@menus/ui'
import { createEventDispatcher, onDestroy, onMount } from 'svelte'
import { Modal } from '../modal/index.js'
import { Email__VerifyChangeModal_c } from './Email__VerifyChangeModal_c.js'
const ctx = ui_ctx__getContext()
const dispatch = createEventDispatcher()
const consumer_login_user_ = consumer_login_user__(ctx)
const Email__ChangeModal_i_ = Email__ChangeModal_i__(ctx)
const VerificationCodeModal_i_ = VerificationCodeModal_i__(ctx)
export const _ = new Email__VerifyChangeModal_c(ctx, dispatch)
export const open = _.open, close = _.close, is_modal_open_ = _.is_modal_open_
onMount(()=>_.onMount())
onDestroy(()=>_.onDestroy())
</script>

{#if $is_modal_open_}
	<!-- Email__VerifyChangeModal -->
	<Modal {_} class="Email__VerifyChangeModal">
		<div slot="header">
			<button type="button" class="close" on:click={()=>close(false)}>
				<div class="delete-icon"></div>
			</button>
			<h4 class="modal-title">Verify Email</h4>
		</div>
		<div>
			<p>
				Your email {$consumer_login_user_.Email} is not verified.
				Please either verify your current email or add a new email.
			</p>
		</div>
		<div slot="footer">
			<div class="row">
				<button type="button"
								class="col-xs-12 btn btn-lg"
								on:click={async ()=>{
									await close()
									$VerificationCodeModal_i_.open({ Email: $consumer_login_user_.Email }).then()
								}}
				>Verify Email</button>
			</div>
			<div class="row">
				<button type="button"
								class="col-xs-12 btn btn-lg"
								on:click={async ()=>{
									await close()
									$Email__ChangeModal_i_.open().then()
								}}
				>Change Email</button>
			</div>
			<div class="row">
				<button type="button"
								class="col-xs-12 btn btn-lg"
								on:click={()=>close()}
				>Cancel</button>
			</div>
		</div>
	</Modal>
{/if}

<style lang="scss" global>
@import "@menus/css/lib";
#sapper {
	.Email__VerifyChangeModal {
		.modal-dialog {
			p {
				margin-top: 8px;
			}
			.modal-footer {
				.row {
					margin-top: 8px;
				}
			}
		}
	}
}
</style>
