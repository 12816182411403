<script lang="ts">
import { compact } from '@ctx-core/array'
import { consumer_login_user__, Email__ChangeModal_i__, Email_or_Phone_, Phone__ChangeModal_i__ } from '@menus/domain'
import type { Email__ChangeModal_I, Phone__ChangeModal_I } from '@menus/types'
import { ui_ctx__getContext } from '@menus/ui'
import { required_errors_, validation, ValidationMessages } from '@menus/validation'
import { createEventDispatcher, onDestroy, onMount } from 'svelte'
import { VerificationCode_c } from './VerificationCode_c.js'
export let verification_code = '', busy = false, Email = '', Phone = '', change_contact_button__to_render = false
const ctx = ui_ctx__getContext()
const dispatcher = createEventDispatcher()
const Email__ChangeModal_i_ = Email__ChangeModal_i__(ctx)
const Phone__ChangeModal_i_ = Phone__ChangeModal_i__(ctx)
export const _ = new VerificationCode_c(ctx, dispatcher)
const busy_ = _.busy_
$: busy = $busy_
const subtitle_ = _.subtitle_
const title_ = _.title_
const verify_success_ = _.verify_success_
const Email_ = _.Email_
$: _.Email_.$ = Email
const Phone_ = _.Phone_
$: _.Phone_.$ = Phone
const consumer_login_user_ = consumer_login_user__(ctx)
const verification_code_ = _.verification_code_
$: verification_code_.$ = verification_code
let ChangeModal_i_:Email__ChangeModal_I|Phone__ChangeModal_I
$: ChangeModal_i_ =
	$Email_
	? $Email__ChangeModal_i_
	: $Phone_
		? $Phone__ChangeModal_i_
		: null
let Email_or_Phone:string
$: Email_or_Phone = Email_or_Phone_({ Email: $Email_, Phone: $Phone_ })
let prompt_message:string
$: prompt_message = `Please check your ${compact([
	$Email_ ? `email ${$Email_}` : null,
	$Phone_ ? `phone ${$Phone_}` : null,
]).join(' or ')} and verify it to enter the verification code you received.`
onMount(()=>_.onMount())
onDestroy(()=>_.onDestroy())
let verification_code__error_a:string[]
</script>

<div class="VerificationCode">
	{#if $verify_success_}
		<div class="success-icon"></div>
	{:else}
		<form disabled={!verification_code} on:submit|preventDefault={()=>_.verification_code__verify()}>
			<p>{prompt_message}</p>
			<label for="code">Verification Code</label>
			<input class="form-control input-sm" type="text"
						 placeholder="Verification Code"
						 id="code"
						 bind:value={verification_code}
						 use:validation={verification_code, ['Verification Code', required_errors_]}
						 on:errors={$=>verification_code__error_a = $.detail}
			>
			<ValidationMessages errors={verification_code__error_a} input_tooltip={true}></ValidationMessages>
			<div class="button-container">
				<button class="btn btn-lg col-xs-12 btn-success"
								disabled={!verification_code}
								type="submit"
				>Verify Code</button>
				{#if ($Email_ && !$consumer_login_user_?.IsEmailValidated) || ($Phone_ && !$consumer_login_user_?.IsPhoneValidated)}
					<div>
						<button class="btn btn-lg col-xs-12"
										on:click|preventDefault={$=>_.verification_code__resend($)}
						>Resend Code</button>
					</div>
				{/if}
				{#if change_contact_button__to_render}
					<button class="btn btn-lg col-xs-12 change-contact-btn"
									on:click|preventDefault={()=>ChangeModal_i_.open()}
					>Change {Email_or_Phone}</button>
				{/if}
			</div>
			<input type="submit" style="display: none;"/>
		</form>
	{/if}
	{#if $title_}
		<div class="success-section-title">{ $title_ }</div>
		<div class="success-section-subtitle">{ $subtitle_ }</div>
	{/if}
</div>
<style lang="scss" global>
@import "@menus/css/lib";
.VerificationCode {
	text-align: center;
	label {
		padding: 4px 0;
	}
	.success-section-title {
		font-weight: $lato-black;
		font-size: 24px;
		margin-bottom: 12px;
	}
	.success-section-subtitle {
		font-size: 18px;
		margin-bottom: 66px;
	}
	.button-container {
		.btn {
			margin-top: 8px;
		}
	}
}
</style>
