<script lang="ts">
import {
	App_Name__,
	is_navigating__onclick,
	platform_settings__is_platform__,
	page__path__is_backoffice$_,
	ServiceType$_
} from '@menus/domain'
import { ui_ctx__getContext } from '@menus/ui'
import { createEventDispatcher } from 'svelte'
export let white = false, disabled = false
const ctx = ui_ctx__getContext()
const dispatch = createEventDispatcher()
const App_Name_ = App_Name__(ctx)
const page__path__is_backoffice$ = page__path__is_backoffice$_(ctx)
const platform_settings__is_platform_ = platform_settings__is_platform__(ctx)
const ServiceType$ = ServiceType$_(ctx)
let query:string
$: query = $ServiceType$ ? `?ServiceType=${$ServiceType$}` : ''
let href:string
$: href =
	$platform_settings__is_platform_
	? `/${query}`
	: $page__path__is_backoffice$
		? '/backoffice'
		: `/search${query}`
</script>

<a class="PlatformIcon"
	 class:disabled={disabled}
	 disabled={disabled ? disabled : null}
	 {href}
	 on:click={$=>{
		 if (!disabled) is_navigating__onclick(ctx, $)
	 }}
	 on:click={$=>dispatch('click', $)}
>
  {#if $platform_settings__is_platform_}
    <div class="app-name">{$App_Name_}</div>
  {:else if $platform_settings__is_platform_ != null}
    <div class="menu-logo" class:menu-logo-icon={!white} class:menu-logo-white-icon={white}></div>
  {/if}
</a>

<style lang="scss" global>
@import "@menus/css/lib";
.PlatformIcon {
	padding-top: calc(env(safe-area-inset-top, 0));
	padding-left: calc(env(safe-area-inset-left, 0) + 16px);
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover, &:focus {
		text-decoration: none;
	}
	.app-name {
		position: relative;
		font-weight: 900;
		font-size: 18px;
		height: $navbar-height-sm;
		line-height: $navbar-height-sm;
		// @media (max-width: $screen-sm-max) {
		// 	padding: 0 0 0 36px;
		// 	font-size: 18px;
		// }
	}
	.menu-logo {
		height: $navbar-height-sm;
	}
}
</style>
