<script lang="ts">
import { consumer_login_user__, email__change__title__ } from '@menus/domain'
import { ui_ctx__getContext } from '@menus/ui'
import { email_errors_, validation, ValidationMessages } from '@menus/validation'
import { createEventDispatcher, onDestroy, onMount } from 'svelte'
import { Modal } from '../modal/index.js'
import { Email__ChangeModal_c } from './Email__ChangeModal_c.js'
const ctx = ui_ctx__getContext()
const dispatch = createEventDispatcher()
const consumer_login_user_ = consumer_login_user__(ctx)
const email__change__title_ = email__change__title__(ctx)
export const _ = new Email__ChangeModal_c(ctx, dispatch)
export const open = _.open, close = _.close, is_modal_open_ = _.is_modal_open_
const new_email_ = _.new_email_
let new_email_errors:string[]
onMount(()=>_.onMount())
onDestroy(()=>_.onDestroy())
function submit() {
	new_email_errors.length || _.email__change()
}
let has_Email:boolean
$: has_Email = !!$consumer_login_user_?.Email
</script>

{#if $is_modal_open_}
	<!-- Email__ChangeModal -->
	<Modal {_} class="Email__ChangeModal">
		<div slot="header">
			<button type="button" class="close" on:click={()=>close(false)}>
				<div class="delete-icon"></div>
			</button>
			<h4 class="modal-title">{$email__change__title_}</h4>
		</div>
		<form on:submit|preventDefault={()=>submit()}>
			{#if has_Email}
				<div class="form-group input-container">
					<label for="current_email">Current Email{$consumer_login_user_?.IsEmailValidated ? ' (Verified)' : ''}</label>
					<div class="m-input-group has-addon-right">
						<input type="text" id="current_email"
									 class="form-control"
									 disabled={true}
									 value={$consumer_login_user_?.Email}>
					</div>
				</div>
			{/if}
			<div class="form-group input-container">
				<label for="new_email">New Email</label>
				<div class="m-input-group has-addon-right">
					<input type="text" id="new_email"
								 class="form-control"
								 bind:value={$new_email_}
								 use:validation={$new_email_, ['New Email', email_errors_]}
								 on:errors={evt=>new_email_errors = evt.detail}
					>
					<ValidationMessages errors={new_email_errors}></ValidationMessages>
				</div>
			</div>
			<input type="submit">
		</form>
		<div slot="footer">
			<button type="button"
							class="col-xs-12 btn btn-lg"
							disabled={!$new_email_}
							on:click={()=>submit()}
			>{$email__change__title_}</button>
		</div>
	</Modal>
{/if}

<style lang="scss" global>
@import "@menus/css/lib";
.Email__ChangeModal {
	input[type="submit"] {
		display: none;
	}
	.modal-body {
		padding: 40px;
		.confirm-message {
			font-size: 18px;
			font-weight: $lato-bold;
		}
	}
	.modal-footer {
		padding: 20px 20px 100px;
		border-top: 1px solid #DBDBDB;
		@media (max-width: $screen-xs-max) {
			// TODO: Fix this in future
			position: unset !important;
			padding-bottom: 150px !important;
		}
		@media (min-width: $screen-sm-min) {
			padding-bottom: 20px;
			text-align: right;
		}
		@media (max-width: $screen-xs-max) {
			.action-buttons {
				display: flex;
				flex-direction: column-reverse;
				.btn {
					width: 100%;
					margin-top: 12px;
				}
			}
		}
	}
}
</style>
