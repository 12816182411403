<script lang="ts">
import { ui_ctx__getContext } from '@menus/ui'
import { createEventDispatcher, onDestroy, onMount } from 'svelte'
import { Modal } from '../modal/index.js'
import { ConfirmModal_c } from './ConfirmModal_c'
const ctx = ui_ctx__getContext()
const dispatch = createEventDispatcher()
export const _ = new ConfirmModal_c(ctx, dispatch)
export const open = _.open, close = _.close, is_modal_open_ = _.is_modal_open_
const data_ = _.data_
onMount(()=>_.onMount())
onDestroy(()=>_.onDestroy())
</script>

{#if $is_modal_open_}
	<!-- Confirm Modal -->
	<Modal {_} class="ConfirmModal confirm-modal">
		<div slot="header">
			<button type="button" class="close" on:click={()=>close(false)}>
				<div class="delete-icon"></div>
			</button>
			<h4 class="modal-title">{ $data_?.title }</h4>
		</div>
		<div class="confirm-message">{ $data_?.message }</div>
		<div slot="footer">
			<div class="row">
				<button type="button"
								class="col-xs-6 btn btn-lg {$data_?.cancel_class}"
								on:click={()=>close(false)}
				>{ $data_?.cancel_text }</button>
				<button type="button"
								class="col-xs-6 btn btn-lg {$data_?.ok_class}"
								on:click={()=>close(true)}
				>{ $data_?.ok_text }</button>
			</div>
		</div>
	</Modal>
{/if}

<style lang="scss" global>
@import "@menus/css/lib";
.ConfirmModal {
	.modal-body {
		padding: 40px;
		.confirm-message {
			font-size: 18px;
			font-weight: $lato-bold;
		}
	}
	.modal-footer {
		padding: 20px 20px 100px;
		border-top: 1px solid #DBDBDB;
		@media (max-width: $screen-xs-max) {
			// TODO: Fix this in future
			position: unset !important;
			padding-bottom: 150px !important;
		}
		@media (min-width: $screen-sm-min) {
			padding-bottom: 20px;
			text-align: right;
		}
		@media (max-width: $screen-xs-max) {
			.action-buttons {
				display: flex;
				flex-direction: column-reverse;
				.btn {
					width: 100%;
					margin-top: 12px;
				}
			}
		}
	}
}
</style>
